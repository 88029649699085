@import "flickity-fade/flickity-fade.css";

@font-face {
	font-family: "Oceanic Grotesk";
	font-style: normal;
	font-weight: normal;
	font-stretch: normal;
	font-display: auto;
	src: url("./OceanicGrotesk-Regular.woff2") format("woff2"),
	  url("./OceanicGrotesk-Regular.woff") format("woff");
}

@font-face {
	font-family: "Oceanic Grotesk";
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
	font-display: auto;
	src: url("./OceanicGrotesk-Medium.woff2") format("woff2"),
	  url("./OceanicGrotesk-Medium.woff") format("woff");
}

@font-face {
	font-family: "Oceanic Text";
	font-style: normal;
	font-weight: 100;
	font-stretch: normal;
	font-display: auto;
	src: url("./Oceanic-Text-Regular.woff2") format("woff2"),
	  url("./Oceanic-Text-Regular.woff") format("woff");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

	.uppertype {
		@apply text-sm md:text-base font-grotesk uppercase tracking-wider md:tracking-[0.128em] leading-tight;
	}

}

html {
	box-sizing: border-box;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

:root {
	--delay: 500ms;
}

.slider {
	@apply absolute w-full h-full top-0 left-0 z-10 bg-black;
	transition-delay: calc( var(--delay) * 2 );

	.slide {
		@apply w-full h-full absolute top-0 left-0 opacity-0 transition;
		transition-duration: var(--delay);
		img {
			@apply w-full h-full block object-cover;
		}
	}

	@apply opacity-0 transition;
}

.flickity-slider {
	@apply w-full h-full;
}

.logo {
	@apply text-black transition;
	transition-delay: calc( var(--delay) * 2 );
}

.blurb {
	@apply -translate-y-[250%] transition;
	transition-delay: calc( var(--delay) * 3 );
}

.form {
	@apply translate-y-[250%] transition;
	transition-delay: calc( var(--delay) * 3 );
}

.is-init {
	.logo {
		@apply text-white;
	}
	.slider {
		@apply opacity-100;
	}
	.blurb,
	.form {
		@apply translate-y-0;
	}
}

.slide {
	&.is-selected {
		@apply opacity-100;
	}	
}
